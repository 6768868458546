/**
 * Created by larissa on 29/01/2017.
 */
//import Vue from 'vue'
//import Vuex from 'vuex'

//Vue.use(Vuex);
//import createPersistedState from 'vuex-persistedstate'
//import * as Cookies from 'js-cookie'

var vars = vars || {};
const store = new Vuex.Store({
    state: {
        procedure: {
                selection: (vars.procedure && vars.procedure.selection)? vars.procedure.selection:null,
                update: 'clinics',
                values:[]

        },
        procedureType: {
            values: [1,0],
            selection: null,
            update: 'clinics',
            url: null,
        },
        staff: {
            values: (vars.staff && vars.staff.values)? vars.staff.selection:null,
            selection: (vars.staff && vars.staff.values)? vars.staff.selection:null,
            update: 'dates'
        },
        clinics: {
            values: (vars.clinics && vars.clinics.values)? vars.clinics.selection:null,
            selection: (vars.clinics && vars.clinics.values)? vars.clinics.selection:null,
            update: 'staff'
        },
        events: null,
        calendar: false,
        hour: null,
        minute: null,
        date: null
    },
    mutations: {

    }
    ,
   /* plugins: [
        createPersistedState({
            getState: (key) => Cookies.getJSON(key),
            setState: (key, state) => Cookies.set(key, state, { expires: 3, secure: true })
        })
    ]*/
});
