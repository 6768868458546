var app = app || {};

app.redirect = function (url) {
    location.href = url;
};

app.replaceUrl = function (url, replacements) {

    for (var key in replacements) {
        url = url.replace('{' + key + '}', encodeURIComponent(replacements[key]));
    }

    return url;
};

app.queryString = function (data) {

    var query = '';
    $.each(data, function (index, value) {
        query += '&' + index + '=' + (value ? value : '');
    });

    return query.substring(1);
};

app.firstKey = function (object) {

    var result = null;
    $.each(object, function (index, value) {
        result = result ? result : index;
    });

    return result;
};

app.size = function (object) {

    if (!object) return;

    var counter = 0;
    $.each(object, function (index, value) {
        counter++;
    });

    return counter;
};

app.handlers = {};

app.trigger = function (event, payload) {
    if (app.handlers[event]) {
        $.each(app.handlers[event], function (index, handler) {
            handler(payload);
        });
    }
};

app.listen = function (event, handler) {
    if (!app.handlers[event]) {
        app.handlers[event] = [];
    }
    app.handlers[event].push(handler);
};

app.print = function (selector) {

    var html = $(selector).html();
    var title = $(document).find("title").text();

    var popup = window.open('', title, 'height=' + $(window).height() + ',width=' + $(window).width());
    popup.document.write('<html><head><title>' + title + '</title>');
    $('head').find('link[media="print"]').each(function () {
        popup.document.write('<link rel="stylesheet" type="text/css" href="' + $(this).attr('href') + '">');
    });
    popup.document.write('</head><body>' + html + '</body></html>');
    popup.document.close(); // necessary for IE >= 10
    popup.focus(); // necessary for IE >= 10
    setTimeout(function () {
        popup.print();
        popup.close();
    }, 200);

    return true;
}

app.device = {};

app.device.isAndroid = function () {
    return navigator.userAgent.match(/Android/i);
};

app.device.isIos = function () {
    return navigator.userAgent.match(/iPad|iPhone|iPod/i) && !window.MSStream;
};

app.device.isWP = function () {
    return navigator.userAgent.match(/Windows Phone/i);
};

app.device.isBlackberry = function () {
    return navigator.userAgent.match(/BlackBerry/i);
};

app.mapsByDevice = function () {
    if (app.device.isWP()) {
        return 'bingmaps:?q=' + encodeURI(this.address);

    } else if (app.device.isAndroid()) {
        return 'geo:0,0?q=' + encodeURI(this.address);

    } else if (app.device.isBlackberry()) {
        return "javascript:blackberry.launch.newMap({'address': {'address1': '" + encodeURI(this.address) + "'}})";

    } else if (app.device.isIos()) {
        return '//maps.google.com/?saddr=Current%20Location&daddr=' + encodeURI(this.address);

    }

    return '//maps.google.com/?q=' + encodeURI(this.address);
};

app.coordinatesToAddress = function (coordinates, callback) {
    http.get("http://maps.google.com/maps/api/geocode/json?latlng=" + coordinates.lat + "," + coordinates.lng + "&sensor=false", function (data) {
        if (data.results[0]) {
            callback(data.results[0].formatted_address);
        }
    });
};

Number.prototype.formatMoney = function (decimals, currency) {
    if (typeof currency == 'undefined') {
        currency = '€';
    }
    if (typeof decimals == 'undefined') {
        decimals = 2;
    }
    var result = this.toFixed(decimals) + ' ' + currency;

    return result.replace('.', ',');
};

$.fn.scrollIntoView = function(speed, offset, callback) {
    speed = speed ? speed : 500;
    offset = offset ? offset : 0;
    var top = $(this).offset().top - offset;
    $('body, html').animate({scrollTop: top}, speed, 'swing', callback);
    return $(this);
};