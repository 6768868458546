var app = app || {};
app.vue = app.vue || {};
//const router = new VueRouter();

//import Vue from 'vue';
Vue.component('calendar', {template : '#calendar-template',

    props: ['from', 'date', 'offset', 'months', 'days',  'closed', 'events'],

    data: function() {
        return {
            holidays: vars.holidays,
            weekdayList: {'mon':'01','tue':'02', 'wed':'03', 'thu':'04', 'fri':'05', 'sat':'06', 'sun':'07'}
        };
    },

    mounted: function() {
        this.initCalendarWithEvents();
        //  setTimeout(this.refreshAll.bind(this), 800);
    },

    watch: {
        date: function() {
            this.refreshSelectedDate();
        },
        events: function(){
            //this.refreshAll();
            $(".responsive-calendar").responsiveCalendar('clearAll');
            var i = 0;
            setTimeout(function(){
                $.each(this.events, function(k, v){
                    if(++i == 1){
                        $(".responsive-calendar").responsiveCalendar(k);
                        return false;
                    }
                });
                $(".responsive-calendar").responsiveCalendar('edit',this.events);
            }.bind(this), 800);

        }
    },

    computed: {
        daysList: function() {
            return this.days.split(',');
        }
    },

    methods: {
        initCalendarWithEvents: function() {
            var vm = this;
            var focusDate = new Date();
            var i=0;
            vmBooking.hours = [];

            $.each(this.events, function(k, v){
                if(++i == 1){
                    focusDate = new Date(k);
                    return false;
                }
            });
            focusDate.setDate(focusDate.getDate());
            var formatedFocusDate = focusDate.getFullYear()+'-'+('0' + (focusDate.getMonth()+1)).slice(-2);

            $(".responsive-calendar").responsiveCalendar({
                time: formatedFocusDate,
                onDayClick: function() {
                    vm.selectDate(this);
                },
                onMonthChange: function() {
                    // setTimeout(vm.refreshAll.bind(vm), 800);
                },
                events: vm.events,
                translateMonths: this.months.split(',')
            });

            // init selection
            /*setTimeout(function() {
             var selected = $(".responsive-calendar .day.active a");
             var available = $(".responsive-calendar .day.available:first a");
             if(selected.length) vm.selectDate(selected);
             else if (available.length) vm.selectDate(available);
             }, 900);*/
        },

        selectDate: function(elm) {
            if(!$(elm).parent().hasClass('special')) return;
            vmBooking.selectDate(elm);
            return true;
        },

        formattedElementDate: function(elm) {
            return $(elm).data('year') +
                '-' + this.addLeadingZero($(elm).data('month')) +
                '-' + this.addLeadingZero($(elm).data('day'));
        },

        addLeadingZero: function(num) {
            if (num < 10) {
                return "0" + num;
            } else {
                return "" + num;
            }
        },

        refreshAll: function() {
            this.refreshAvailableDays();
            this.refreshSelectedDate();
        },
        resolveWeekday: function(elm){
            var  d;
            $.each(this.weekdayList, function(k, v){
                if($(elm).hasClass(k)){
                    return d=v;
                }
            });
            return d;

        },

        refreshAvailableDays: function() {
            var first = new Date();
            var vm = this;

            first.setDate(first.getDate() + vm.offset);
            first.setHours(0,0,0,0);

            $('.responsive-calendar .days .day:not(.not-current)').removeClass('available').each(function(){

                var day = $(this).find('a').data('day');
                var month = $(this).find('a').data('month');
                var year = $(this).find('a').data('year');

                var current = new Date(year, month-1, day);
                var formatted = vm.formattedElementDate($(this).find('a'));

                var isInFuture =  $(this).hasClass('future');//first.getTime() <= current.getTime();
                var isSaturday = $(this).hasClass('sat');
                var isSunday = $(this).hasClass('sun');
                var isHoliday = typeof vm.holidays !== 'undefined';
                var isClosed = false;

                var currentWeekday = vm.resolveWeekday($(this));
                $.each(vm.closed, function(k,v){
                    if ( (v==currentWeekday)){
                        isClosed = true;
                    }
                });

                if(isInFuture && (/*!isHoliday &&*/ !isSunday && !isClosed)) {
                    $(this).addClass('available');
                }

                /* if(isInFuture && (isSaturday && vm.specialSaturdayTime || isHoliday && vm.specialSaturdayTime && !vm.holidays[formatted].is_closed)) {
                 $(this).addClass('available holiday');
                 }*/
            });
        },

        refreshSelectedDate: function() {
            var days = $('.responsive-calendar .days .day');

            days.removeClass('active');
            if(!this.date) return;

            var d = this.date.split('-');
            var target = days.find(
                'a[data-year="'+d[0]+'"][data-month="'+parseInt(d[1])+'"][data-day="'+parseInt(d[2])+'"]'
            );

            target.parent().addClass('active');
        }
    }
});


