/*------------------------------------------------------------------
# [General]
------------------------------------------------------------------*/

var doc = document.documentElement;
doc.setAttribute('data-useragent', navigator.userAgent);
var windowWidth = window.innerWidth;
var windowHeight = window.innerHeight;

window.log=function(){log.history=log.history||[];log.history.push(arguments);if(this.console){console.log(Array.prototype.slice.call(arguments))}};
var StartUp = function(runnable) {$(document).ready(runnable.run);}

window.addEventListener("load", function(){
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#000000",
                "text": "#ffffff"
            },
            "button": {
                "background": "#000000",
                "text": "#ffffff"
            }
        },
        "theme": "edgeless",
        "position": "bottom",
        "content": {
            "message": "Spletno mesto uporablja piškotke za zagotavljanje boljše uporabniške izkušnje in spremljanje statistike obiska. Z nadaljnjo uporabo spletnega mesta soglašate s piškotki.",
            "dismiss": "x",
            "link": "več",
            "href": "/zasebnost"
        }
    })});

/*------------------------------------------------------------------
# [Banner]
------------------------------------------------------------------*/

$('.banner .close').click(function() {
	$(this).parent().stop().slideUp('fast');
});



/*------------------------------------------------------------------
# [Navigation]
------------------------------------------------------------------*/

$('.menu-trigger').click(function() {

	if (!$(this).hasClass('open')) {
		$(this).addClass('open');
		$('.nav-wrapper').addClass('open');
		//$('.menu').stop().slideDown('fast').addClass('open');
	} else {
		$(this).removeClass('open');
		$('.nav-wrapper').removeClass('open');
		//$('.menu').stop().slideUp('fast').removeClass('open');
	}
});


/*------------------------------------------------------------------
# [Expand toggle - mobile]
------------------------------------------------------------------*/

$('.expand-toggle').click(function(e) {
	windowWidth = window.innerWidth;
	e.preventDefault();

	if ( $(this).parents('.footer').length == 1 && windowWidth >= 1024) {
		return false;
	} else {
		if (!$(this).hasClass('open')) {
			$(this).parent().find('.expand-content').stop().slideDown();
			$(this).addClass('open');
		} else {
			$(this).parent().find('.expand-content').stop().slideUp();
			$(this).removeClass('open');
		}
	}
});


/*------------------------------------------------------------------
# [Show more toggle]
------------------------------------------------------------------*/

$('.show-more-trigger').click(function(e) {
	e.preventDefault();
	$(this).parent().slideUp(200);
	$(this).parent().parent().closest('div').find('.show-more-content').delay(300).slideDown();
});


/*------------------------------------------------------------------
# [Accordion]
------------------------------------------------------------------*/

$('.accordion-trigger').click(function(e) {
	if (!$(this).parent().hasClass('open')) {
		$(this).parent().addClass('open');
		$(this).parent().find('.accordion-content').slideDown();
	} else {
		$(this).parent().removeClass('open');
		$(this).parent().find('.accordion-content').slideUp();
	}
});


/*------------------------------------------------------------------
# [Window.resize]
------------------------------------------------------------------*/

$(window).scroll(function() {
	windowWidth = window.innerWidth;
	
	if ($('.site-header').offset().top >= 10) {
		$('.site-header').addClass('shadow');
	} else {
		$('.site-header').removeClass('shadow');
	}
});


/*------------------------------------------------------------------
# [Document.ready]
------------------------------------------------------------------*/

$(document).ready(function() {

	//var headerHeight = $('.site-header').height();
	//$('.main').css('padding-top',headerHeight);

    $(".easyii-box").fancybox();
	//date selector
	if ($('.date-selector').length) {
		pickmeup('.date-selector', {
			format  : 'd. m. Y'
		});
	}

	//select2
	if ($('select').length) {
		$('select').select2({
    		minimumResultsForSearch: -1
		});
	}

	// home slider
	if ($('.home-slider').length) {
		$('.home-slider').slick({
			dots: true,
			fade: true,
			infinite: true,
			speed: 300,
			autoplay: false,
			autoplaySpeed: 4000,
			arrows: false,
			mobileFirst: true
		});
	};

	// poslovalnice slider
	if ($('.poslovalnice-slider').length) {
		$('.poslovalnice-slider').slick({
			dots: false,
			infinite: true,
			speed: 300,
			fade: true,
			arrows: false,
			cssEase: 'ease-in-out',
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						dots: true
					}
				}
			]
		});

		$('#prev-poslovalnica').on('click', function(){
			$('.poslovalnice-slider').slick('slickPrev');
		});
		$('#next-poslovalnica').on('click', function(){
			$('.poslovalnice-slider').slick('slickNext');
		});
	};

	// Featured testimonial slider on home page
	if ($('.featured-testimonials-slider').length) {
		$('.featured-testimonials-slider').slick({
			fade: true,
			arrows: false,
			cssEase: 'ease-in-out'
		});

		$('#prev-testimonial').on('click', function(){
			$('.featured-testimonials-slider').slick('slickPrev');
		});

		$('#next-testimonial').on('click', function(){
			$('.featured-testimonials-slider').slick('slickNext');
		});
	}

	// Before-after slider
	if ($('.before-after-slider').length) {
		$('.before-after-slider').slick({
			fade: true,
			arrows: false,
			cssEase: 'ease-in-out'
		});

		$('#prev-case').on('click', function(){
			$('.before-after-slider').slick('slickPrev');
		});

		$('#next-case').on('click', function(){
			$('.before-after-slider').slick('slickNext');
		});
	}


    $(".show-on-map").click(function(e){
        e.preventDefault();
        var addressString = $(this).data("address");
        var iframe = $(this).data("iframe");
        $.fancybox('<iframe width="640" height="480" frameborder="0" style="border:0" src="'+iframe+'" allowfullscreen></iframe>');
        if(addressString) {
            var parts = addressString.split("#");

           // if(parts.length === 4) {
             //   $.fancybox('<iframe width="640" height="480" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/directions?q=place_id:' + parts[1] + '&key=' +googleMapApiKey+  '&origin=&destination=estetika+nobilis+ljubljana&language=' +lang+ '" allowfullscreen></iframe>');
           // }
        }
    });

});


(function ($) {
    $.fn.yiiAjaxForm = function (options) {
        function getFormData($form, formSettings) {
            var $button = formSettings.submitObject;
            var data = '&' + formSettings.settings.ajaxParam + '=' + $form.attr('id');
            if ($button && $button.length && $button.attr('name')) {
                data += '&' + $button.attr('name') + '=' + $button.attr('value');
            }

            return $form.serialize() + data;
        }

        function init() {
            var $form = $(this);
            var formSettings = $form.data('yiiActiveForm');
            var ajaxSettings = {
                url: $form.attr('action'),
                type: $form.attr('method'),
                data: getFormData($form, formSettings),
                dataType: formSettings.settings.ajaxDataType,
                context: this,
            };

            $.ajax($.extend({}, ajaxSettings, options));

            return false;
        }

        this.on('beforeSubmit', init);

        return this;
    };
})(window.jQuery);

/**
 * @author ComFreek <http://stackoverflow.com/users/603003/comfreek>
 * @link http://stackoverflow.com/a/16069817/603003
 * @license MIT 2013-2015 ComFreek
 * @license[dual licensed] CC BY-SA 3.0 2013-2015 ComFreek
 * You MUST retain this license header!
 */
(function (exports) {
    function valOrFunction(val, ctx, args) {
        if (typeof val == "function") {
            return val.apply(ctx, args);
        } else {
            return val;
        }
    }

    function InvalidInputHelper(input, options) {

        input.setCustomValidity(valOrFunction(options.defaultText, window, [input]));

        function changeOrInput() {

            if (input.value == "") {
                input.setCustomValidity(valOrFunction(options.emptyText, window, [input]));
            } else {
                input.setCustomValidity("");
            }
            setMsg();
        }

        function setMsg() {
            if (input.validity.badInput) {
                input.setCustomValidity(valOrFunction(options.invalidText, window, [input]));
            }
            if (input.validity.rangeOverflow) {
                input.setCustomValidity(valOrFunction(options.invalidText, window, [input]));
            }
            if (input.validity.rangeUnderflow) {
                input.setCustomValidity(valOrFunction(options.invalidText, window, [input]));
            }
            if (input.validity.valueMissing) {
                input.setCustomValidity(valOrFunction(options.emptyText, window, [input]));
            }
        }

        function invalid() {
            setMsg();
        }

        input.addEventListener("change", changeOrInput);
        input.addEventListener("input", changeOrInput);
        input.addEventListener("invalid", invalid);
    }

    exports.InvalidInputHelper = InvalidInputHelper;
})(window);

var placeSearch, autocomplete;
var componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    //administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
};

function initAutocomplete() {
    // Create the autocomplete object, restricting the search to geographical
    // location types.
    autocomplete = new google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */(document.getElementById('autocomplete')),
        {types: ['geocode']});
    autocomplete.setComponentRestrictions(
        {'country': ['si', 'hr', 'it', 'at', 'hu', 'ru', 'mk', 'de', 'fr','ba', 'cz', 'ro', 'sk', 'ua', 'gb', 'bg', 'be', 'al', 'ad', 'at', 'by', 'be', 'ba', 'bg', 'hr', 'cz', 'dk', 'ee', 'fo', 'fi', 'fr', 'de', 'gi', 'gr', 'va', 'hu', 'is', 'ie', 'it', 'lv', 'li', 'lt', 'lu', 'mk', 'mt', 'md', 'mc', 'me', 'nl', 'no', 'pl', 'pt', 'ro', 'ru', 'sm', 'rs', 'sk', 'si', 'es', 'se', 'ch', 'ua', 'gb']});
    // ['al', 'ad', 'at', 'by', 'be', 'ba', 'bg', 'hr', 'cz', 'dk', 'ee', 'fo', 'fi', 'fr', 'de', 'gi', 'gr', 'va', 'hu', 'is', 'ie', 'it', 'lv', 'li', 'lt', 'lu', 'mk', 'mt', 'md', 'mc', 'me', 'nl', 'no', 'pl', 'pt', 'ro', 'ru', 'sm', 'rs', 'sk', 'si', 'es', 'se', 'ch', 'ua', 'gb']
    // When the user selects an address from the dropdown, populate the address
    // fields in the form.
    autocomplete.addListener('place_changed', fillInAddress);
}

function fillInAddress() {
    // Get the place details from the autocomplete object.
    var place = autocomplete.getPlace();

    if (!place.hasOwnProperty("address_components")) return;
    for (var component in componentForm) {
        document.getElementById('bookingform-'+component).value = '';
        document.getElementById('bookingform-'+component).disabled = false;
    }

    // Get each component of the address from the place details
    // and fill the corresponding field on the form.
    for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
            var val = place.address_components[i][componentForm[addressType]];
            document.getElementById('bookingform-'+addressType).value = val;
        }
    }
}

// Bias the autocomplete object to the user's geographical location,
// as supplied by the browser's 'navigator.geolocation' object.
function geolocate() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
            var geolocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            var circle = new google.maps.Circle({
                center: geolocation,
                radius: position.coords.accuracy
            });
            autocomplete.setBounds(circle.getBounds());
        });
    }
}
var pac_input = document.getElementById('autocomplete');

(function pacSelectFirst(input){
    // store the original event binding function
    if (!input || !input.length) return false;
    var _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;

    function addEventListenerWrapper(type, listener) {
        // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
        // and then trigger the original listener.

        if (type == "keydown") {
            var orig_listener = listener;
            listener = function (event) {
                var suggestion_selected = $(".pac-item-selected").length > 0;
                if ((event.which == 13 || event.which == 9 ) && !suggestion_selected) {
                    var simulated_downarrow = $.Event("keydown", {keyCode:40, which:40})
                    orig_listener.apply(input, [simulated_downarrow]);
                }

                orig_listener.apply(input, [event]);
            };
        }

        // add the modified listener
        _addEventListener.apply(input, [type, listener]);
    }

    if (input.addEventListener)
        input.addEventListener = addEventListenerWrapper;
    else if (input.attachEvent)
        input.attachEvent = addEventListenerWrapper;

})(pac_input);


/**
 * Wrap an "unsafe" promise
 */
function safePromise(promise) {
    return promise
        .then(result => [null, result])
        .catch(error => [error, null]);
}

function safeFunction(fn) {
    return function(...args) {
        let error = null;
        let result = null;

        try {
            result = fn.apply(this, args);
        } catch (e) {
            error = e;
        }

        return safePromise(error ? Promise.reject(error) : Promise.resolve(result));
    }
}